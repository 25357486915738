<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <!-- 页面头部查询模块 -->
    <template #search>
      <a-form layout="inline">
        <a-form-item label="">
          <a-range-picker v-model:value="searchForm.time" @change="timeChange" :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD" placeholder="提交时间" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.customerType" :options="$store.state.enumAll.CustomerTypeEnum"
            placeholder="客户类型"  />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="客户名称" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.phone" type="number" placeholder="客户电话" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.empName" placeholder="提交人" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.isExpire" placeholder="是否到期">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.orgId" style="width:200px" placeholder="归属部门" allowClear>
            <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.contractStatus" placeholder="审核状态">
            <a-select-option :value="0">待审核</a-select-option>
            <a-select-option :value="1">已通过</a-select-option>
            <a-select-option :value="2">未通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-space>
          <a-button  :loading="searchLoading" type="primary" @click="onSearch"
            :disabled="searchLoading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>

    <template #totalbar>
      <ExclamationCircleTwoTone twoToneColor="#427bb1" /> 当前待审核中的合同有 <a-tag color="#1890ff">{{ totalbarNum }}</a-tag> 条
    </template>
    <!-- 表格主体 -->
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="searchLoading" @change="handleTableChange">
      <template #contractStatus="{ record }">
        <div style="display:flex;justify-content: center;">
          <span class="contract-status">
            <span class="status-show"
              :style="{ color: record.contractStatus.value === 0 ? '#F6C95F' : (record.contractStatus.value === 1 ? '#00FF00' : '#f00') }">•</span>
            {{ record.contractStatus.value === 2 ? '拒绝' : record.contractStatus.label }}
          </span>
        </div>
      </template>
      <template #settlementCycle="{ record }">
        <div>{{ record.settlementCycle }}{{ record.settlementMode ? record.settlementMode.value == 2 ? '月' : '天' : '' }}
        </div>
      </template>
      <template #createTime="{ record }">
        <div>始：{{ record.createTime }}</div>
        <div>末：{{ record.contractEndTime }}</div>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="onApprove(record)">{{ record.contractStatus.value === 0 ? '审批' : '查看' }}</a>
        </span>
      </template>
    </a-table>
    <a-modal v-model:visible="approveShow" :width="1000" @cancel="cancel" :closable="false" footer="">
      <div style="display:flex">
        <div style="width:400px;margin-right:15px;display:flex;justify-content: center;align-items: center;">
          <a-image-preview-group v-show="!reloadShow">
            <div v-for="(item, index) in fileUrls" :key="index" v-show="item.show">
              <a-image :width="400" style="height:400px" :src="item.url">
                <template #placeholder>
                  <a-image :width="400" :height="400" :src="item.url" />
                </template>
              </a-image>
            </div>
          </a-image-preview-group>
          <a-spin size="large" active v-show="reloadShow" />
        </div>
        <div style="flex:1">
          <div v-show="!reloadShow">
            <h3 style="font-weight:bold">合同详情</h3>
            <p class="text"><span style="color:#344563">合同名称：</span>{{ contractDetail.contractName }}</p>
            <p class="text"><span style="color:#344563">合同编号：</span>{{ contractDetail.contractCode }}</p>
            <p class="text"><span style="color:#344563">合同始末：</span>{{ contractDetail.contractStartTime }}—{{
              contractDetail.contractEndTime }}
            </p>
            <p class="text"><span style="color:#344563">审核状态：</span>{{ conversionS(contractDetail.contractStatus.label) }}
            </p>
            <p class="text"><span style="color:#344563">签订时间：</span>{{ contractDetail.createTime }}</p>
            <p class="text"><span style="color:#344563">客户名称：</span>{{ contractDetail.customerName }}</p>
            <p class="text"><span style="color:#344563">客户签约人：</span>{{ contractDetail.empName }}</p>
            <p class="text"><span style="color:#344563">结算周期：</span>{{ contractDetail.settlementCycle }}{{
              contractDetail.settlementMode ? contractDetail.settlementMode.value
                == 2 ? '月' : '天' : '' }}</p>
            <p class="text"><span style="color:#344563">负责人：</span>{{ contractDetail.empName }}</p>
            <h3 style="font-weight:bold">合同审批</h3>
            <div>
              <div><span>审批结果<span style="color:#f00">*</span><span v-if="contractDetail.contractStatus.value !== 0"
                    style="color:#f00;font-size:18px">{{ contractDetail.contractStatus.label }}</span></span></div>
              <a-select v-if="contractDetail.contractStatus.value === 0 && approveShow" ref="select" style="width: 120px"
                placeholder="请选择" @change="resultChange">
                <a-select-option :value="1">同意</a-select-option>
                <a-select-option :value="2">拒绝</a-select-option>
              </a-select>
            </div>
            <p v-if="contractDetail.contractStatus.value === 0">原因</p>
            <a-textarea v-if="contractDetail.contractStatus.value === 0" style="height:50px;" v-model:value="reason"
              :rows="8" />
            <div style="display:flex;justify-content: flex-end;margin-top:15px">
              <a-button style="background:#F0F2F5;border:none;margin-right:10px" @click="approveShow = false">{{
                contractDetail.contractStatus.value === 0 ? '取消' : '关闭' }}</a-button>
              <a-button style="background:#344563;color:#fff" v-if="contractDetail.contractStatus.value === 0"
                @click="onApproveEdit">审批</a-button>
            </div>
          </div>
          <a-skeleton active v-show="reloadShow" />
        </div>
      </div>
    </a-modal>
  </HxTable>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import HxTable from '@/components/HxTable'
import { ExclamationCircleTwoTone } from '@ant-design/icons-vue'
import { getPageList, getDetail, examine, getStatus } from '@/api/crmManagement/approveContract'
import { conversionS } from '@/utils/util'
import { message } from 'ant-design-vue'

export default {
  components: {
    HxTable,
    ExclamationCircleTwoTone
  },
  setup () {
    const state = reactive({
      searchForm: {
        time: '',
        name: '',
        phone: '',
        orgId: null,
        customerType: ''
      },
      mirrorSearchForm: {},
      contractDetail: {
        contractStatus: {}
      },
      reason: null,
      totalbarNum: 0,
      reloadShow: false,
      searchLoading: false,
      approveShow: false,
      result: {},
      approveObj: {},
      columns: [
        {
          title: '客户类型',
          dataIndex: 'customerType.label',
          width: '3%'
        },
        // {
        //   title: '归属部门',
        //   dataIndex: '',
        //   width: '3%'
        // },
        {
          title: '客户名称',
          width: '8%',
          dataIndex: 'customerName'
        },
        {
          title: '联系电话',
          width: '5%',
          dataIndex: 'mobile'
        },
        {
          title: '结算方式',
          dataIndex: 'settlementMode.label',
          width: '3%'
        },
        {
          title: '结算周期',
          dataIndex: 'settlementCycle',
          width: '3%',
          slots: {
            customRender: 'settlementCycle'
          }
        },
        {
          title: '合同名称',
          width: '8%',
          dataIndex: 'contractName'
        },
        {
          title: '合同编号',
          width: '5%',
          dataIndex: 'contractCode'
        },
        {
          title: '合同开始-结束时间',
          dataIndex: 'createTime',
          width: '5%',
          slots: {
            customRender: 'createTime'
          }
        },
        {
          title: '是否到期',
          width: '3%',
          dataIndex: 'isExpire.label'
        },
        {
          title: '审核状态',
          dataIndex: 'contractStatus',
          width: '3%',
          slots: {
            customRender: 'contractStatus'
          }
        },
        {
          title: '提交人',
          dataIndex: 'empName',
          width: '2%'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '2%',
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      listData: [],
      fileUrls: [],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 查询
    const onSearch = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const loadData = () => {
      state.searchLoading = true
      getPageList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
          getStatus({ value: 0 })
            .then(res => {
              if (res.code === 10000) state.totalbarNum = res.data
            }).catch(err => { console.log(err) })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.searchLoading = false
      })
    }
    const timeChange = (e, v) => {
      state.searchForm.createStartTime = v[0]
      state.searchForm.createEndTime = v[1]
    }
    const customerType = (e, v) => {
      state.searchForm.customerType = v
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 打开审批面板
    const onApprove = (e) => {
      state.approveObj = e
      state.approveShow = true
      state.reloadShow = true
      getDetail(e.id, {})
        .then(res => {
          if (res.code === 10000) {
            state.fileUrls = res.data.fileUrls
            if (state.fileUrls === null) {
              state.fileUrls = []
              state.fileUrls[0] = {
                url: require('@/assets/images/nullImg.jpg'),
                show: true
              }
            } else {
              state.fileUrls[0].show = true
            }
            state.contractDetail = res.data
            state.reloadShow = false
          }
        }).catch(err => { console.log(err) })
    }
    const resultChange = (e, v) => {
      state.result.value = e
    }
    // 审批确认
    const onApproveEdit = () => {
      if (state.result.value !== null && state.result.value !== undefined) {
        if (state.result.value !== 2) requestAPi()
        else if (state.result.value === 2) {
          if (state.reason) requestAPi()
          else message.error('请填写拒绝原因')
        }
        function requestAPi () {
          examine({
            customerId: state.approveObj.customerId,
            id: state.approveObj.id,
            examineType: state.result.value,
            remark: state.reason
          }).then(res => {
            if (res.code === 10000) {
              state.approveShow = false
              message.success('审批成功,已', res.msg)
              state.result = {}
              state.reason = ''
              loadData()
            }
          }).catch(err => { console.log(err) })
        }
      } else message.error('你还未选择审批结果')
    }
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const cancel = () => {
      state.fileUrls = []
      state.approveShow = false
    }
    return {
      ...toRefs(state),
      reset,
      cancel,
      timeChange,
      customerType,
      onSearch,
      resultChange,
      loadData,
      handleTableChange,
      onApprove,
      onApproveEdit,
      conversionS
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
@import '../tool/index.less';

:deep(.ant-select-selector) {
  padding-top: 4px 11px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
